<template>
  <div class="number_counter flex_between gap-[16px] rounded-[30px] overflow-hidden">
    <button @mousedown="startCounter('minus')" @mouseup="stopCounter" @mouseleave="stopCounter"
      @click="activateCounter('minus')" class="flex-auto h-full pl-[20px]">
      <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12.5H20" stroke="#201A1E" stroke-width="1.2" stroke-linecap="round" />
      </svg>
    </button>
    <!-- restrict max input -->
    <span class="text-lg text-font-3 block min-w-[3rem] text-center">{{ itemCount }}</span>
    <button @mousedown="startCounter('add')" @mouseup="stopCounter" @mouseleave="stopCounter"
      @click="activateCounter('add')" class="flex-auto h-full pr-[20px]">
      <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.3 5C12.3 4.83431 12.1657 4.7 12 4.7C11.8343 4.7 11.7 4.83431 11.7 5V12.2H4.5C4.33431 12.2 4.2 12.3343 4.2 12.5C4.2 12.6657 4.33431 12.8 4.5 12.8H11.7V20C11.7 20.1657 11.8343 20.3 12 20.3C12.1657 20.3 12.3 20.1657 12.3 20V12.8H19.5C19.6657 12.8 19.8 12.6657 19.8 12.5C19.8 12.3343 19.6657 12.2 19.5 12.2H12.3V5Z"
          stroke="#201A1E" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  minCount: {
    type: Number,
    default: 1,
  },
  maxCount: {
    type: Number,
    default: 999,
  },
  productID: {
    type: String,
    default: null,
  },
});
const accountInfoStore = userAccountInfoStore();
// const counter = ref<number>(props.minCount);
const interval = ref<any>(null);

const itemCount = computed(() => {
  return accountInfoStore.cartItemCount(props.productID);
});
const activateCounter = (type: string) => {
  if (type === "add" && itemCount.value < props.maxCount) {
    // counter.value++;
    accountInfoStore.changeItemQuantity(props.productID,);
  } else if (type === "minus" && itemCount.value > props.minCount) {
    // counter.value--;
    accountInfoStore.changeItemQuantity(props.productID, "decrement");
  }
  /* if (counter.value > props.maxCount) counter.value = props.maxCount;
  counter.value = Math.abs(counter.value); */
};
function stopCounter() {
  clearInterval(interval.value);
  interval.value = null;
}
function startCounter(type: string) {
  interval.value = setInterval(() => {
    activateCounter(type);
  }, 200);
}
</script>

<style></style>
