import axios from "axios";
export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      axios: () =>
        axios.create({
          withCredentials: true,
          baseURL: "https://api/korner.ng/api/v1/",
        }),
    },
  };
});
