import { default as about_45usLsHO86BXPDMeta } from "/var/www/apps/korner.ng/pages/about-us.vue?macro=true";
import { default as address_45bookK6N833tyxMMeta } from "/var/www/apps/korner.ng/pages/account/address-book.vue?macro=true";
import { default as index5kbqu3qvoiMeta } from "/var/www/apps/korner.ng/pages/account/index.vue?macro=true";
import { default as contact_45support0CDFzoZWa1Meta } from "/var/www/apps/korner.ng/pages/account/orders/contact-support.vue?macro=true";
import { default as indexGx1VqEh6CHMeta } from "/var/www/apps/korner.ng/pages/account/orders/index.vue?macro=true";
import { default as view_45order_45detailsXp49Xiv9wjMeta } from "/var/www/apps/korner.ng/pages/account/orders/view-order-details.vue?macro=true";
import { default as profile3h4mON7pQPMeta } from "/var/www/apps/korner.ng/pages/account/profile.vue?macro=true";
import { default as saved_45cardsWuSCd0vFD2Meta } from "/var/www/apps/korner.ng/pages/account/saved-cards.vue?macro=true";
import { default as transaction_45historyr5BMgAD8NiMeta } from "/var/www/apps/korner.ng/pages/account/transaction-history.vue?macro=true";
import { default as walletNszGGJidX3Meta } from "/var/www/apps/korner.ng/pages/account/wallet.vue?macro=true";
import { default as account0cL9YIW8T1Meta } from "/var/www/apps/korner.ng/pages/account.vue?macro=true";
import { default as forgot_45passwordzX0jzhzqfIMeta } from "/var/www/apps/korner.ng/pages/auth/forgot-password.vue?macro=true";
import { default as login3liNY8f01HMeta } from "/var/www/apps/korner.ng/pages/auth/login.vue?macro=true";
import { default as registerQBdcy9EccvMeta } from "/var/www/apps/korner.ng/pages/auth/register.vue?macro=true";
import { default as contact_45usm78wZf8xgmMeta } from "/var/www/apps/korner.ng/pages/contact-us.vue?macro=true";
import { default as frequently_45asked_45questionsPMgpCQbl8HMeta } from "/var/www/apps/korner.ng/pages/frequently-asked-questions.vue?macro=true";
import { default as indexIl5SK7dTbHMeta } from "/var/www/apps/korner.ng/pages/index.vue?macro=true";
import { default as privacy_45policypVf891zylVMeta } from "/var/www/apps/korner.ng/pages/privacy-policy.vue?macro=true";
import { default as _91sub_45category_93xpeFwaHkzNMeta } from "/var/www/apps/korner.ng/pages/search/[category]/[sub-category].vue?macro=true";
import { default as indexnOYiAjoKZoMeta } from "/var/www/apps/korner.ng/pages/search/[category]/index.vue?macro=true";
import { default as _91category_93WWBpPX2D5DMeta } from "/var/www/apps/korner.ng/pages/search/[category].vue?macro=true";
import { default as indexj9C7MAYpooMeta } from "/var/www/apps/korner.ng/pages/search/index.vue?macro=true";
import { default as searchHkWaxVyz8wMeta } from "/var/www/apps/korner.ng/pages/search.vue?macro=true";
import { default as index31zarvpeacMeta } from "/var/www/apps/korner.ng/pages/shopping-cart/index.vue?macro=true";
import { default as order_45summarywUCiqJJt8pMeta } from "/var/www/apps/korner.ng/pages/shopping-cart/order-summary.vue?macro=true";
import { default as _91id_93GWv9ss5pRxMeta } from "/var/www/apps/korner.ng/pages/single/[id].vue?macro=true";
import { default as terms_45and_45conditionsRy323pD2VyMeta } from "/var/www/apps/korner.ng/pages/terms-and-conditions.vue?macro=true";
import { default as verify_45payment4vfv3xJkNBMeta } from "/var/www/apps/korner.ng/pages/verify-payment.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/var/www/apps/korner.ng/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: account0cL9YIW8T1Meta?.name,
    path: "/account",
    component: () => import("/var/www/apps/korner.ng/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-address-book",
    path: "address-book",
    component: () => import("/var/www/apps/korner.ng/pages/account/address-book.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/apps/korner.ng/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-contact-support",
    path: "orders/contact-support",
    component: () => import("/var/www/apps/korner.ng/pages/account/orders/contact-support.vue").then(m => m.default || m)
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/var/www/apps/korner.ng/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-view-order-details",
    path: "orders/view-order-details",
    component: () => import("/var/www/apps/korner.ng/pages/account/orders/view-order-details.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/var/www/apps/korner.ng/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-saved-cards",
    path: "saved-cards",
    component: () => import("/var/www/apps/korner.ng/pages/account/saved-cards.vue").then(m => m.default || m)
  },
  {
    name: "account-transaction-history",
    path: "transaction-history",
    component: () => import("/var/www/apps/korner.ng/pages/account/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "account-wallet",
    path: "wallet",
    component: () => import("/var/www/apps/korner.ng/pages/account/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordzX0jzhzqfIMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login3liNY8f01HMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerQBdcy9EccvMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/var/www/apps/korner.ng/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "frequently-asked-questions",
    path: "/frequently-asked-questions",
    meta: frequently_45asked_45questionsPMgpCQbl8HMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/frequently-asked-questions.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/apps/korner.ng/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/var/www/apps/korner.ng/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: searchHkWaxVyz8wMeta?.name,
    path: "/search",
    component: () => import("/var/www/apps/korner.ng/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93WWBpPX2D5DMeta?.name,
    path: ":category()",
    component: () => import("/var/www/apps/korner.ng/pages/search/[category].vue").then(m => m.default || m),
    children: [
  {
    name: "search-category-subcategory",
    path: ":subcategory()",
    component: () => import("/var/www/apps/korner.ng/pages/search/[category]/[sub-category].vue").then(m => m.default || m)
  },
  {
    name: "search-category",
    path: "",
    meta: indexnOYiAjoKZoMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/search/[category]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search",
    path: "",
    meta: indexj9C7MAYpooMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/search/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "shopping-cart",
    path: "/shopping-cart",
    meta: index31zarvpeacMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/shopping-cart/index.vue").then(m => m.default || m)
  },
  {
    name: "shopping-cart-order-summary",
    path: "/shopping-cart/order-summary",
    component: () => import("/var/www/apps/korner.ng/pages/shopping-cart/order-summary.vue").then(m => m.default || m)
  },
  {
    name: "single-id",
    path: "/single/:id()",
    meta: _91id_93GWv9ss5pRxMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/single/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/var/www/apps/korner.ng/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "verify-payment",
    path: "/verify-payment",
    meta: verify_45payment4vfv3xJkNBMeta || {},
    component: () => import("/var/www/apps/korner.ng/pages/verify-payment.vue").then(m => m.default || m)
  }
]