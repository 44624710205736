import { defineStore } from "pinia";
import { getAppResourceState } from "~/stores/default";

export const useAppResourceStore = defineStore("appResource", {
  state: () => {
    return getAppResourceState();
  },

  getters: {},

  actions: {
    resetState() {
      Object.assign(this, getAppResourceState());
    },
    async fetchCategories() {
      if (this.categories.length > 0) return;
      alert("23");
      try {
        const { data: categoryData } = await useFetch(
          this.apiBase +
            "user/category/search?paginate[per_page]=25&paginate[current_page]=1&order_by[key]=name&order_by[direction]=asc"
        );
        if (categoryData.value) {
          console.log(categoryData.data.categories.data);
          this.categories = categoryData.data.categories.data;
        }
      } catch (error) {}
    },
  },
});
