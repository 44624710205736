import { useAppResourceStore } from "~/stores/appResources";

export const useCategories = async () => {
  const appResourceStore = useAppResourceStore();
  if (appResourceStore.categories.length > 0) return;
  try {
    const { data: categoryData } = await useCustomFetch(
      "user/category/search?paginate[per_page]=25&paginate[current_page]=1&order_by[key]=name&order_by[direction]=asc",
      { method: "get" }
    );
    if (categoryData) {
      console.log(categoryData.data.categories.data);
      appResourceStore.categories = categoryData.data.categories.data;
    }
  } catch (error) {}
};
