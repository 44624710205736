import { defineStore } from "pinia";
import { getAccountInfoState } from "~/stores/default";

export const userAccountInfoStore = defineStore("accountInfo", {
  state: () => {
    return getAccountInfoState();
  },

  getters: {
    cartTotal() {
      return function (isRegular = false) {
        return this.cart.reduce((total, item) => {
          return (
            total +
            (isRegular
              ? item.lowest_price_variation.regular_price
              : item.lowest_price_variation.price) *
              item.quantity
          );
        }, 0);
      };
    },
    cartItemCountTotal() {
      return this.cart.length;
    },
    cartItemCount() {
      return function (itemID) {
        const itemInCart = this.cart.find((cartItem) => cartItem.id === itemID);
        if (itemInCart) {
          return itemInCart.quantity;
        }
        return 0;
      };
    },
  },

  actions: {
    resetState() {
      Object.assign(this, getAccountInfoState());
    },
    addToCart(item) {
      console.log("from cart", this.cart);
      const itemInCart = this.cart.find((cartItem) => cartItem.id === item.id);
      if (itemInCart) {
        itemInCart.quantity += 1;
        return;
      }
      item.quantity = item.minimum_order;
      this.cart.push(item);
    },
    removeFromCart(itemID) {
      const itemInCart = this.cart.find((cartItem) => cartItem.id === itemID);
      if (itemInCart) {
        this.cart.splice(this.cart.indexOf(itemInCart), 1);
      }
    },
    changeItemQuantity(itemID, type = "increment") {
      const itemInCart = this.cart.find((cartItem) => cartItem.id === itemID);
      if (itemInCart) {
        itemInCart.quantity += type == "increment" ? 1 : -1;
      }
    },
    clearCart() {
      this.cart = [];
    },
  },
});
